import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'principal',
    loadChildren: () => import('./principal/principal.module').then( m => m.PrincipalPageModule)
  },
  {
    path: 'sede',
    loadChildren: () => import('./sede/sede.module').then( m => m.SedePageModule)
  },
  {
    path: 'reporte',
    loadChildren: () => import('./reporte/reporte.module').then( m => m.ReportePageModule)
  },
  {
    path: 'monitoreo',
    loadChildren: () => import('./monitoreo/monitoreo.module').then( m => m.MonitoreoPageModule)
  },
  {
    path: 'mapa',
    loadChildren: () => import('./mapa/mapa.module').then( m => m.MapaPageModule)
  },
  {
    path: 'variables',
    loadChildren: () => import('./variables/variables.module').then( m => m.VariablesPageModule)
  },
  {
    path: 'yutaho',
    loadChildren: () => import('./yutaho/yutaho.module').then( m => m.YutahoPageModule)
  },
  {
    path: 'history',
    loadChildren: () => import('./history/history.module').then( m => m.HistoryPageModule)
  },
  {
    path: 'sensor',
    loadChildren: () => import('./sensor/sensor.module').then( m => m.SensorPageModule)
  },
  {
    path: 'agendamiento',
    loadChildren: () => import('./agendamiento/agendamiento.module').then( m => m.AgendamientoPageModule)
  },
  {
    path: 'replanteo-form',
    loadChildren: () => import('./replanteo-form/replanteo-form.module').then( m => m.ReplanteoFormPageModule)
  },
  {
    path: 'despacho',
    loadChildren: () => import('./despacho/despacho.module').then( m => m.DespachoPageModule)
  },
  {
    path: 'usuarios',
    loadChildren: () => import('./usuarios/usuarios.module').then( m => m.UsuariosPageModule)
  },
  {
    path: 'recaudos',
    loadChildren: () => import('./recaudos/recaudos.module').then( m => m.RecaudosPageModule)
  },
  {
    path: 'popover2',
    loadChildren: () => import('./popover2/popover2.module').then( m => m.Popover2PageModule)
  },
  {
    path: 'ticket',
    loadChildren: () => import('./ticket/ticket.module').then( m => m.TicketPageModule)
  },
  {
    path: 'electrico',
    loadChildren: () => import('./electrico/electrico.module').then( m => m.ElectricoPageModule)
  },
  {
    path: 'crear-ticket',
    loadChildren: () => import('./crear-ticket/crear-ticket.module').then( m => m.CrearTicketPageModule)
  },
  {
    path: 'informacion-usuario',
    loadChildren: () => import('./informacion-usuario/informacion-usuario.module').then( m => m.InformacionUsuarioPageModule)
  },
  {
    path: 'historico-usuario',
    loadChildren: () => import('./historico-usuario/historico-usuario.module').then( m => m.HistoricoUsuarioPageModule)
  },  {
    path: 'variblestwo',
    loadChildren: () => import('./variblestwo/variblestwo.module').then( m => m.VariblestwoPageModule)
  },
  {
    path: 'sedetwo',
    loadChildren: () => import('./sedetwo/sedetwo.module').then( m => m.SedetwoPageModule)
  },
  {
    path: 'variablestre',
    loadChildren: () => import('./variablestre/variablestre.module').then( m => m.VariablestrePageModule)
  }



];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
