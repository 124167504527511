import { Injectable } from '@angular/core';
import { ajax } from 'jquery';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

V = new Array();
I = new Array();
VP = new Array();
IP = new Array();
VACG = new Array();
IACG = new Array();
Pconsumida = new Array();
Pgenerada = new Array();
ValoresHoras = new Array();
ContGen = 0;
ContCon = 0;
Mifare  = 0;
Mfallo  = 0;
Battery = 0;
Puerta  = 0;
Recarga = 0;
FechaVen = '0';
Alarmas = 0;
UltimaEscritura = '0';
FechaVal = '0';
FOLDER = 'Despacho'
ULTIMO = 'Cargando.....';
informacionBeneficiario
Id_Usuario;
Piness;
Pines2;
Id_Client;
idRole;
Pines;
piness= [];
USERDATALIWA;
UserName;
lastSession;
CLIENTDATALIWA;
Clientdata;
idBenefit;
donePoll;
view = false;
municipalitiesUrl = 'https://www.datos.gov.co/resource/xdk5-pm3f.json';
public appPages = [
  { title: 'Login', url: '/login', icon: 'person-circle' }
];
Aulas = [];
Aulas2 = [];
Aulas3 = [];
AULASEL = [];
Popover;
Popover2;
mapStyle:any [] =[
  {
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#212121"
      }
    ]
  },
  {
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#212121"
      }
    ]
  },
  {
    "featureType": "administrative",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "administrative.country",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "administrative.locality",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#bdbdbd"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#181818"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#1b1b1b"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#2c2c2c"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#8a8a8a"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#373737"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#3c3c3c"
      }
    ]
  },
  {
    "featureType": "road.highway.controlled_access",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#4e4e4e"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "transit",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#000000"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#3d3d3d"
      }
    ]
  }
];
  constructor( public nav: NavController ,private http: HttpClient) { }

  consultar(datos, respuesta) {
    console.log('Consultando');
    ajax({
      data: datos, cache: false, dataType: 'json', type: 'post',
      //url: 'http://192.168.0.10:80/DatabaseMON.php',
      url: 'https://www.php.engenius.com.co/DatabaseCENS.php',
      success(data, textStatus, jQxhr) {
        console.warn('bien', data, textStatus);
      },
      error(jqXhr, textStatus, errorThrown) {
        console.warn(errorThrown);
        console.warn(jqXhr);
        console.warn(textStatus);
      }
    }).then((response) => {
      respuesta(null, response);
    }).catch((err) => {
      respuesta(err);
    });
  }
  consultarvv(datos, respuesta) {
    console.log('Consultando');
    ajax({
      data: datos, cache: false, dataType: 'json', type: 'post',
      url: 'https://www.php.engenius.com.co/DatabaseCENS.php',
      success(data, textStatus, jQxhr) {
        console.warn('bien', data, textStatus);
      },
      error(jqXhr, textStatus, errorThrown) {
        console.warn('mal');
      }
    }).then((response) => {
      respuesta(null, response);
    }).catch((err) => {
      respuesta(err);
    });
  }

  consultar2(datos, respuesta) {
    console.log('Consultando');
    ajax({
      data: datos, cache: false, dataType: 'json', type: 'post',
      //url: 'http://192.168.0.10:80/DatabaseMON.php',
      url: 'https://www.php.engenius.com.co/DatabaseLIWA.php',
      success(data, textStatus, jQxhr) {
        console.warn('bien', data, textStatus);
      },
      error(jqXhr, textStatus, errorThrown) {
        console.warn(errorThrown);
        console.warn(jqXhr);
        console.warn(textStatus);
      }
    }).then((response) => {
      respuesta(null, response);
    }).catch((err) => {
      respuesta(err);
    });
  }
  //messageUrl = 'http://192.168.0.10:8001/';
  messageUrl = 'https://bbdd-naz-yzotmrdvwq-uc.a.run.app';

  sendMessage(messageModel: any): Observable<any> {
    return this.http.post(this.messageUrl, messageModel);
  }
  /*initData() {
    const vari = localStorage.getItem('USERDATALIWA');
    if (vari != null) {
      this.USERDATALIWA = vari.split(',');
      console.log('USERDATALIWA', this.USERDATALIWA);
      this.UserName = this.USERDATALIWA[3];
      this.Id_Usuario = this.USERDATALIWA[0];
      this.lastSession = this.USERDATALIWA[19];
      this.idRole = this.USERDATALIWA[2];
      const client = localStorage.getItem('CLIENTDATALIWA');
      this.CLIENTDATALIWA = client.split(',');
      this.Clientdata = this.CLIENTDATALIWA;
      console.log('CLIENTDATALIWA', this.CLIENTDATALIWA);
      this.Id_Client = this.CLIENTDATALIWA[0];
      this.validSession();
    } else {
      this.nav.navigateRoot(['/login']);
    }

  }
  validSession() {
    let now = moment().format('YYYY-MM-DD');
    let last = this.lastSession;
    let dayNow = now.split('-')[2];
    let lastDay = last.split('-')[2];
    if (dayNow !== lastDay) {
      localStorage.clear();
      this.nav.navigateRoot(['/login']);
    }
  }
*/
  momentFromNow(date: any) {
    moment.lang('es');
    var diff = moment(date).fromNow();
    return diff;
  }


  momentSencilla(date: any) {
    moment.lang('es');
    var diff = moment(date).fromNow();
    return diff;
  }

  returnProvinces(): Observable<any> {
    return this.http.get(this.municipalitiesUrl);
  }

  asignarPages(){
    this.appPages = [

      //{ title: 'Registro de usuarios', url: '/usuarios', icon: 'scale' },
      //{ title: 'Agendamiento', url: '/agendamiento', icon: 'scale' },
      { title: 'Monitoreo', url: '/monitoreo', icon: 'speedometer' },
      { title: 'Recaudos', url: '/recaudos', icon: 'cash' },
      //{ title: 'Ticket', url: '/ticket', icon: 'map' },
    ];
  }
}
